import React from "react"
import { graphql } from "gatsby"

import Layout from '../components/layout'
import '../components/scss/post.scss'

const NewsPost = props => {
  const post = props.data.microcmsNews
  return (
    <Layout>
      <div className='postshow'>
        <h1>{post.title}</h1>
        <div>
          <span>{post.tag.name}</span>
        </div>
        <img
          src={post.image.url}
          width={160}
          height={110}
          alt="News投稿のイメージ画像"
        />
        <p
          dangerouslySetInnerHTML={{
            __html: `${post.content}`,
          }}
          id='body'></p>
      </div>
    </Layout>
  )
}

export default NewsPost

export const query = graphql`
 query($id: String!) {
   microcmsNews(id: { eq: $id }) {
     title
     date
     tag {
       name
     }
     image {
       url
     }
     content
   }
 }
`